<template>
  <div class="kursi page">
    <Navigation />
    <div class="container">
      <div class="inner">
        <h1 class="section__title"> Ko mēs<br/>piedāvājam</h1>
        <div class="wrapper left">
          <p class="block one">
            Izglītības biedrība "Izaugsme Plus" piedāvā tālākizglītības programmu "Strādāt kopā ar mentoru", kas palīdzēs skolotājiem veidot radošu un izzinošu mācību vidi un procesu, kas ietver skolotāja un skolēna sadarbību, kā arī skolēnu vajadzību ievērošanu. Programmas īstenošanas darba forma ir  mentorings, kas ir divpusēja sadarbība, kurā skolotājs strādā kopā ar mācību spēku, saņem atbalstu un padomus un kopīgi izstrādā mācību materiālus. <br/><br/>
            Programma ir piemērota  sākumizglītības  skolotājiem, kuri vēlas pilnveidot savas prasmes un izprast jaunākās izglītības pamatnostādnes. Programma piedāvā prakstiskus rīkus un metodes, kā integrēt dažādus mācību priekšmetus un pieejas, lai radītu personificētu un motivējošu mācību vidi.
          </p>
          <p class="block two">
            Skolu reformas pamatnostādnes saistītas ar sabiedrības gaidām: rīcībspējīgs, brīvs un atbildīgs, radoši un kritiski domājošs skolēns, spējīgs uzņemties atbildību, analizēt un pamatot savu rīcību un viedokli.
  Prakse rāda, ka maz tiek veltīta uzmanība skolēnu prasmēm iedziļināties un apstrādāt daudzveidīgus datus, darboties komandā, piedāvāt risinājumus nestandarta situācijām, veidot sakarības starp teorētiski apgūto un reāli dzīvē pieredzēto, analizēt paveikto un izvirzīt mērķus nākamajiem darbiem. Nosaukto prasmju apguvei palīdz kompetenču pieeja. 
  Aktuāls ir jautājums, vai šobrīd minēto prasmju apguve pietiekamā līmenī atspoguļojas skolotāju organizētajā mācību procesā? Kā palīdzēt skolotājam plānot un īstenot mācību procesu atbilstoši šīm pamatnostādnēm ?
          </p>
        </div>
        <div class="wrapper right">
          <p class="questions one">
            Ko sabiedrība gaida no skolotāja? <br/>
            Kā palīdzēt skolotājam īstenot mācību procesu saskaņā ar reformas nostādnēm?
          </p>
          <p class="block one">
            Piedāvātā tālākizglītības programma <span>“Strādāt kopā ar mentoru“</span> palīdzēs skolotājiem rast atbildi, kā nodrošināt radošu, izzinošu mācību vidi, kas ietver skolotāja un skolēna sadarbību un skolēnu vajadzību ievērošanu.
          </p>
          <h3 class="link one"> Apskati <span><router-link to="/programma">kursa programmu</router-link></span> </h3>
          <p class="block two">
            Programmas īstenošanas darba forma - mentorings jeb pušu sadarbība - novērošana, klausīšanās, jautājumu uzdošana, radošu paņēmienu pielietošana.
          </p>
          <p class="questions two"> Ar ko mūsu piedāvātā darba forma atšķiras no ierastās lekcijas? </p>
          <p class="block three"> Galvenā atšķirība - skolotāja un mācību spēka sadarbība, kas izpaudīsies kā kopīga izzināmo jautājumu formulēšana, integrēto mācību nodarbību modelēšana, izstrādājot praktiski lietojamu materiālu. </p>
          <h3 class="link two">Piesakies <span><router-link to="/pieteikties">šeit</router-link></span></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'KursiView',
  components: {
    Navigation
  }
}
</script>

<style scoped lang="scss">
  .container {
    background: radial-gradient(50% 50% at 50% 50%, rgb(188 184 197 / 99%) 0%, rgba(141, 120, 187, 0.990208) 52.95%, #fff4fe 100%), linear-gradient(180deg, rgba(141, 124, 175, 0.99) 0%, rgba(173, 123, 168, 0.990208) 54.69%, #ffffff 100%);

    @media only screen and (max-width: 600px) {
      background: linear-gradient(
        30deg,
        hsl(260deg 24% 59%) 0%,
        hsl(260deg 22% 62%) 8%,
        hsl(259deg 20% 66%) 16%,
        hsl(259deg 16% 70%) 24%,
        hsl(259deg 12% 73%) 32%,
        hsl(265deg 11% 78%) 40%,
        hsl(275deg 14% 83%) 48%,
        hsl(285deg 20% 89%) 55%,
        hsl(297deg 35% 94%) 63%,
        hsl(306deg 53% 95%) 71%,
        hsl(306deg 32% 85%) 78%,
        hsl(306deg 27% 76%) 85%,
        hsl(306deg 25% 67%) 93%,
        hsl(306deg 23% 58%) 100%
      )   }
  }

  p {
    text-align: left;
    font-size: 18px;
  }

  h3 {
    font-weight: normal;
  }

  .inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 auto;
  }
  .wrapper {
    width: 40%;

    @media only screen and (max-width: 600px) {
      width: 80%;
    }

  }

  .wrapper.left {
    p {
      text-align: justify;
    }
    .block.one {
      margin: 0;
    }
  }

  .wrapper.right {
    p {
      text-align: justify;
    }
  }

  .section__title {
    text-align: left;
    font-weight: normal;
    font-style: italic;
    font-size: 48px;
    width: 100%;
    margin-left: 95px;

    @media only screen and (max-width: 600px) {
      width: 80%;
      margin-left: 0;
    }
  }

  .questions.one {
    font-size: 24px;
    width: 80%;
    margin-top: 0;
    text-align: left !important;
  }
  .questions.two {
    font-size: 24px;
    width: 60%;
    margin-top: 0;
    text-align: left !important;
  }

  .link.one {
    text-align: left;
    span {
      font-size: 24px;
      cursor: pointer;
      border-bottom: 1px solid;
    }
    a {
      text-decoration: none;
      color: #2c3e50;
    }
  }

  .link.two {
    font-size: 48px;
    text-align: right;

    span {
      font-style: italic;
      cursor: pointer;
    }
    a {
      text-decoration: none;
      color: #2ec0c4;

      @media only screen and (max-width: 600px) {
        color: white;
      }
    }
  }

</style>
