<template>
  <router-view/>
</template>
<script>

export default {
  name: 'App',
  data() {
    return {

    }
  },
  mounted() {
    var h = window.innerHeight;
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
}
.page {
}
.container {
  margin: 0 auto;
  height: 100%;
}
.inner {
  width: 1400px;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
</style>
