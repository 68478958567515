<template>
  <div class="landing page">
    <Navigation />
    <div class="container">
      <div class="inner">
        <div class="block first">
          <p>
            Izsenis skolotāju profesija ir sabiedrības uzmanības lokā. Mainoties valdošajām sociālajām un politiskajām tendencēm, mainās skolotāju darbības mērķi un uzdevumi, arī sabiedrības attieksme pret skolotāju nav viennozīmīga.Kādas mūsdienās ir sabiedrības gaidas un kādi ir skolotāju pedagoģiskie izaicinājumi?
          </p>
        </div>
        <div class="block second">
          <div class="vertical-line"></div>
          <p>
            Ko sabiedrība gaida no skolotāja? <br/>
            Kā palīdzēt skolotājam īstenot mācību procesu saskaņā ar reformas nostādnēm?
          </p>
        </div>
        <div class="block third">
          <p>
            Izglītības biedrība “Izaugsme plus” ir radījusi tālākizglītības programmu “Strādāt kopā ar mentoru”
          </p>
          <div class="nav__btn">
            <router-link to="/kursi">uzzini vairāk</router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'LandingView',
  components: {
    Navigation
  },
  mounted() {
    var h = window.innerHeight;
    console.log(h)
    let el = document.querySelector('.inner')
    let navHeight = document.querySelector('.navigation').offsetHeight
    el.style.height = `${h - navHeight}px`
  }
}
</script>

<style scoped lang="scss">
  .container {
    background: radial-gradient(50% 50% at 50% 50%, rgb(188 184 197 / 99%) 0%, rgba(141, 120, 187, 0.990208) 52.95%, #fff4fe 100%), linear-gradient(180deg, rgba(141, 124, 175, 0.99) 0%, rgba(173, 123, 168, 0.990208) 54.69%, #ffffff 100%);

    @media only screen and (max-width: 600px) {
      background: linear-gradient(
        30deg,
        hsl(260deg 24% 59%) 0%,
        hsl(267deg 25% 64%) 8%,
        hsl(273deg 25% 70%) 16%,
        hsl(280deg 26% 75%) 24%,
        hsl(287deg 27% 82%) 32%,
        hsl(294deg 31% 88%) 40%,
        hsl(302deg 47% 94%) 48%,
        hsl(305deg 100% 98%) 55%,
        hsl(305deg 100% 98%) 63%,
        hsl(305deg 100% 99%) 71%,
        hsl(305deg 100% 99%) 78%,
        hsl(305deg 100% 99%) 85%,
        hsl(305deg 100% 100%) 93%,
        hsl(0deg 0% 100%) 100%
      );
    }
  }
  p {
    margin: 0;
  }
  .inner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, auto);
    align-items: center;
  }

  .block p {
    text-align: left;
    font-size: 18px;
  }
  .block.first {
    grid-column: 3/6;
    display: none;
  }
  .block.second {
    grid-row: 2;
    grid-column: 3/11;
    width: 80%;

    @media only screen and (max-width: 600px) {
      grid-column: 2/12;
      grid-row: 1;
      margin-top: 20px;
    }

    p {
      font-size: 48px;
      font-style: italic;
      line-height: 55px;

      @media only screen and (max-width: 600px) {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }
  .block.third {
    grid-row: 3;
    grid-column: 8/11;

    @media only screen and (max-width: 600px) {
      grid-column: 4/12;
      grid-row: 2;
    }

    p {
      text-align: justify;
    }
    a {
      text-decoration: none;
      color: black;
      font-weight: bold;
    }
  }
  .nav__btn {
    cursor: pointer;
    text-align: right;
    margin-top: 5px;
  }
</style>
