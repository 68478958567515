import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LandingView from '../views/Landing.vue'
import KursiView from '../views/Kursi.vue'
import AboutView from '../views/About.vue'
import ProgramView from '../views/Program.vue'
import SignupView from '../views/Signup.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingView
  },
  {
    path: '/kursi',
    name: 'Kursi',
    component: KursiView
  },
  {
    path: '/parmums',
    name: 'ParMums',
    component: AboutView,
  },
  {
    path: '/programma',
    name: 'Programma',
    component: ProgramView,
  },
  {
    path: '/pieteikties',
    name: 'Pieteikties',
    component: SignupView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
