<template>
  <div class="signup page">
    <Navigation />
    <div class="container">
      <div class="inner">
        <div class="wrapper left">
          <h1 class="title">Tiešsaites mācības<br/><span class="tt">"Strādāt kopā ar mentoru"</span><br/>Zoom<span></span> platformā.</h1>
          <p class="body">
            Mācību mērķauditorija ir sākumizglītības skolotāji un tās tiek īstenotas nelielās grupās. Pēc mācībām dalībnieki saņems biedrības “Izaugsme Plus” izsniegtu apliecību. <br/><br/>

            Skolotājiem sava dalība mācībās jāpiesaka vismaz 2 dienas pirms to sākuma,
            aizpildot pieteikuma formu šajā lapā. Mācību maksa ir jāieskaita biedrības kontā vismaz 1 dienu pirms kursu sākuma.<br/>Ja skola veic pārskaitījumu par saviem skolotājiem, tad pārskaitījumā jānorāda rēķina nr<br/><br/>

            <span>Maksa par vienu 8 st. mācību moduli - 23€</span><br/><br/>

            <!--Par pārējo novadu skolu skolotāju iespējām pieteikties kursiem būs informācija mūsu mājas lapā <br/><br/>
            Jautājumu gadījumā sazinieties ar mums:-->
          </p>
          <div class="contacts">
            <div class="row">
              <img class="icon" src="../assets/phone.svg"> <p>26463754</p>
            </div>
            <div class="row">
              <img class="icon" src="../assets/email.svg"> <p>anita@izaugsmeplus.lv</p>
            </div>
          </div>
          <div class="line"></div>
          <!--<p class="footnote"><span>*</span>Lai jūs varētu pieslēgties Zoom platformai, lūdzu instalēt ZOOM lietotni (Zoom Client for Meetings)</p> -->
        </div>
        <div class="wrapper right">
          <h3 class="title"> Piesakies: </h3>
          <div class="wrapper__dates">
            <h4 class="module a"> A modulis: </h4>
            <div class="item" v-for='(val, key) in sessions' :key='key'>
              <a v-if="val.module == 'A'" :href=(val.url) target="_blank">{{val.text}}
                <img v-if="val.module == 'A'" src="../assets/icons8-arrow-50.png">
              </a>
            </div>
            <h4 class="module a"> B modulis: </h4>
            <div class="item" v-for='(val, key) in sessions' :key='key'>
              <a v-if="val.module == 'B'" :href=(val.url)  target="_blank">{{val.text}}
                <img v-if="val.module == 'B'" src="../assets/icons8-arrow-50.png">
              </a>
            </div>
          </div>
          <p class="body">Pēc pieteikuma aizpildīšanas, Jūs saņemsiet apstiprinājuma e-pastu ar detalizētu instrukciju, kā pieslēgties nodarbībai uz Jūsu norādīto e-pastu. <br/><br/>
            Pirms mācību sākuma, uz šo pašu e-pastu tiks nosūtīta saite ar pieslēgšanās instrukcijām, bet pēc mācībām - apliecība.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'SignupView',
  components: {
    Navigation
  },
  data() {
    return {
      sessions: [
        {
          text: "14. maijs, plkst. 13:00",
          url: "https://form.jotform.com/231442620950348",
          module: "A"
        },
        {
          text: "16. maijs, plkst. 13:00",
          url: "https://form.jotform.com/231442620950348",
          module: "A"
        },
        {
          text: "4. jūnijs, plkst. 10:00",
          url: "https://form.jotform.com/231442620950348",
          module: "A"
        },
        {
          text: "6. jūnijs, plkst. 10:00",
          url: "https://form.jotform.com/231442620950348",
          module: "A"
        },
        {
          text: "15. maijs, plkst. 13:00",
          url: "https://form.jotform.com/232121948011344",
          module: "B"
        },
        {
          text: "17. maijs, plkst. 13:00",
          url: "https://form.jotform.com/232121948011344",
          module: "B"
        },
        {
          text: "5. jūnijs, plkst. 10:00",
          url: "https://form.jotform.com/232121948011344",
          module: "B"
        },
        {
          text: "7. jūnijs, plkst. 10:00",
          url: "https://form.jotform.com/232121948011344",
          module: "B"
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .container {
    background: linear-gradient(180deg, #B59AEE 0%, rgba(45, 0, 141, 0) 100%);
  }

  p {
    text-align: justify;
    font-size: 18px;
  }

  h3 {
    font-weight: normal;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
    }

    .wrapper {
      width: 40%;

      @media only screen and (max-width: 600px) {
        width: 80% !important;
      }
    }

    .wrapper.left {
      margin-top: 50px;
      .title {
        font-size: 36px;
        font-weight: normal;
        text-align: left;
        line-height: 40px;

        @media only screen and (max-width: 600px) {
          font-size: 30px;
          line-height: 34px;
          margin-bottom: 60px;
        }

        .tt {
          @media only screen and (max-width: 600px) {
            display: block;
            font-size: 40px;
            height: 40px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
      .body {
        span {
          font-size: 24px;
          line-height: 28px;
        }
        .highlight {
          color: #64a7bb;
        }

      }
      .contacts {
        .row {
          display: flex;

          .icon {
            margin-right: 10px;
          }

          p {
            margin: 0;
          }
        }
      }

      .line {
        height: 1px;
        background: black;
        //background: #debc77;
        margin-top: 17px;
        display: none;
      }

      .footnote {
        font-size: 14px;

        span {
          margin-right: 5px;
        }
      }
    }
    .wrapper.right {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 20%;


      .title {
        font-size: 48px;
        text-align: left;
        margin-bottom: 20px;
        width: 100%;
      }

      .wrapper__dates {
        width: 100%;

        .module {
          margin-bottom: 10px;
          text-align: left;
        }

        .item {
          text-align: left;
          margin-top: 1.5px;
          margin-bottom: 1.5px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          a {
            text-decoration: underline;
            color: black;
            font-size: 18px;
            display: flex;
          }

          img {
            width: 23px;
            height: 23px;
            margin-left: 5px;
          }

          a:hover {
            text-decoration: underline;
          }
        }
      }

      .body {
        margin-top: 50px;
        font-size: 18px;
      }
    }
  }


</style>
