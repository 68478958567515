<template>
  <div class="navigation">
    <div class="mobile-nav">
        <router-link to="/"><img class='logo' src='../assets/title.png'></router-link>
      <Slide>
        <router-link to="/kursi" class="link">Kursi</router-link>
        <router-link to="/programma" class="link">Programma</router-link>
        <router-link to="/parmums" class="link">Par mums</router-link>
        <router-link to="/pieteikties" class="link signup">Piesakies!</router-link>
      </Slide>
    </div>
    <nav>
      <router-link to="/"><img class='logo' src='../assets/title.png'></router-link>
      <router-link to="/kursi" class="link">Kursi</router-link>
      <router-link to="/programma" class="link">Programma</router-link>
      <router-link to="/parmums" class="link">Par mums</router-link>
      <router-link to="/pieteikties" class="link signup">Piesakies!</router-link>
    </nav>

  </div>
</template>

<script>
import { Slide } from 'vue3-burger-menu'

export default {
  name: 'Navigation',
  components: {
      Slide // Register your component
  }
}
</script>

<style lang="scss">
  .bm-burger-button {
    right: 36px !important;
    left: unset !important;
    position: relative !important;
    top: unset !important;
  }
  .bm-menu {
    background: linear-gradient(
    120deg,
     hsl(260deg 24% 59%) 0%,
     hsl(264deg 24% 62%) 8%,
     hsl(267deg 25% 64%) 17%,
     hsl(271deg 25% 68%) 26%,
     hsl(274deg 25% 71%) 34%,
     hsl(278deg 26% 74%) 43%,
     hsl(281deg 26% 77%) 51%,
     hsl(285deg 27% 80%) 60%,
     hsl(289deg 28% 84%) 68%,
     hsl(293deg 30% 87%) 76%,
     hsl(297deg 34% 90%) 84%,
     hsl(301deg 45% 94%) 92%,
     hsl(305deg 100% 98%) 100%
    ) !important;

    nav a {
      color: white !important;
      font-size: 24px !important;
    }
  }

  .bm-cross {
    background: white !important;
  }

  .bm-overlay {
    background: none !important;
  }
  .bm-item-list {
    margin-top: 50px !important;
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


  .navigation {
    width: 100%;
    height: 90px;
    border-bottom: 1px solid black;

    @media only screen and (max-width: 600px) {
      display: flex;
      height: 80px;
      align-items: center;
      align-content: center;
    }
  }
  .mobile-nav {
    display: none;

    @media only screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }

    .logo {
      margin-left: 36px;
    }
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    @media only screen and (max-width: 600px) {
      display: none;
    }

    a {
      margin-left: 20px;
      margin-right: 20px;
      text-decoration: none;
      font-size: 18px;
      color: #2c3e50;

      &.router-link-exact-active {
        font-weight: bold;
      }
    }

    .link:hover {
      border-bottom: 1px solid;
    }

    .signup {
      margin-left: 100px;

      @media only screen and (max-width: 600px) {
        margin-left: 20px;
      }

    }
  }
  .logo {
    width: 115px;
    margin-right: 100px;
  }

</style>
