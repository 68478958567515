<template>
  <div class="program page">
    <Navigation />
    <div class="container">
      <div class="inner">
        <div class="wrapper left">
          <p class="body">
            Vērojumi praksē liecina, ka daudziem pedagogiem grūtības sagādā mācību satura, mācību metožu piemērošana skolēnu individuālajām vajadzībām, interesēm un to sasaiste ar caurviju prasmju apguvi, kas ir kompetenču pieejas pamats. Analizējot dažādu autoru teorētiskās atziņas, var konstatēt, ka mācību procesa organizēšanai sākumskolā būtu  jāņem vērā gan personalizētā mācību procesa, gan integrētā, starpdisciplinārā mācību procesa pamatatziņas.<br/><br/>
            <span>Personalizētā mācīšanās</span>
            Tā izpaužas kā mācību procesa pielāgošana katra skolēna individuālajām vajadzībām, prasmēm un interesēm. Tā paredz brīvību gan skolēniem, gan skolotājiem. <br/><br/>
            Sākumizglītībā personalizētā mācīšanās rada pamatu, lai skolēni mācību procesā akcentētu savas stiprās puses, apgūtu prasmi risināt problēmas un radoši domāt. Skolēni strādā kopā ar skolotājiem, lai izvirzītu sev mērķus gan īstermiņā, gan ilgtermiņā, uzņemtos atbildību par savu mācīšanos saistībā ar mācību saturu un standarta prasībām.
            Kā liecina vērojumi praksē,  skolotājiem grūtības rada arī tie skolēni, kuriem mācāmības un mācītības līmenis ir augstāks par  vidējo līmeni. Šie skolēni bieži garlaikojas, jo viņiem nav nepieciešama piepūle piedāvātā mācību satura apguvei. <br/><br/>
            <span>Ieteicamā alternatīva</span> - mācību procesa organizēšana darba stacijās, kura ir alternatīva ierastai mācību stundai. Tā nodrošina iespēju skolēniem pašiem izvirzīt izzināmos jautājumus, patstāvīgi meklēt atbildes, plānot veicamos mācību uzdevumus, atlasīt mācību materiālus, veikt pētniecisko darbību izzināmā jautājuma kontekstā. Kopā ar skolotāju skolēns izvirza nosacījumus mācību darbam, kā arī vērtē savu un kopīgo veikumu darba stacijās.<br/><br/>
            <span>Starppriekšmetu jeb integrētā mācību procesa būtība.</span><br/>
              Tā īstenošana palīdz skolēniem mācību procesā izprast dabā un sabiedrībā pastāvošās likumsakarības, kuras pastāv starp dažādiem mācību priekšmetiem un to saistību ar reālo dzīvi, radot motivāciju izzināt, pētīt un atklāt (J, Tadlock, K Spooner, T.Kerry, A. Humphrey , J.Hattie u.c).
          </p>
        </div>
        <div class="wrapper right">
          <h4 class="module">A modulis</h4>
          <p class="module-body">8 st. moduļa saturs jums palīdzēs rast atbildi uz dažādiem jautājumiem, piemēram, kā veidot mācību saturu, lai saglabātu skolēnu dabisko zinātkāri un izpratni par mācību priekšmetu savstarpējo saistību? Kā mācību saturu saistīt ar skolēnu interesēm un reālo dzīvi? <br/> Kopā ar mentoru (mācību spēks, kurš vada kursus) izstrādāsim integrēto, starpdisciplināro mācību saturu vairākām bloka stundām, nodrošinot caurviju prasmju apguvi. Atbilstoši izzināmajam jautājumam, plānosim sasniedzamo rezultātu, nodrošinot skolēniem iespēju izprast mācību priekšmetu savstarpējo saistību.</p>
          <h4 class="module">B modulis</h4>
          <p class="module-body">8 st. moduļa saturs jums palīdzēs izprast, kāpēc organizēt mācību procesu dažādās darba stacijās. Kā darba stacijās (latviešu val.,matemātika, sociālās zinības, dabaszinības, māksla) plānot diferencētu  mācību saturu atbilstoši skolēnu interesēm un spējām. Kopā ar mentoru (mācību spēks, kurš vada kursus) izstrādāsim diferencētus uzdevumus dažādām darba stacijām. Plānosim mācību procesa norisi, organizācijas formas atbilstoši skolēnu individuālajām vajadzībām.</p>
          <h3 class="title"> Programma <br/>ir <span><a href="/programma.pdf" download>šeit</a></span></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'ProgramView',
  components: {
    Navigation
  },
  mounted() {
    var h = window.innerHeight;
    console.log(h)
    let el = document.querySelector('.inner')
    el.style.minHeight = `${h - 100}px`
  }
}
</script>

<style scoped lang="scss">
  .container {
    background: radial-gradient(50% 50% at 50% 50%, rgb(188 184 197 / 99%) 0%, rgba(141, 120, 187, 0.990208) 52.95%, #fff4fe 100%), linear-gradient(180deg, rgba(141, 124, 175, 0.99) 0%, rgba(173, 123, 168, 0.990208) 54.69%, #ffffff 100%);

    @media only screen and (max-width: 600px) {
          background: linear-gradient(
            30deg,
            hsl(260deg 24% 59%) 0%,
            hsl(260deg 22% 62%) 8%,
            hsl(259deg 20% 66%) 16%,
            hsl(259deg 16% 70%) 24%,
            hsl(259deg 12% 73%) 32%,
            hsl(265deg 11% 78%) 40%,
            hsl(275deg 14% 83%) 48%,
            hsl(285deg 20% 89%) 55%,
            hsl(297deg 35% 94%) 63%,
            hsl(306deg 53% 95%) 71%,
            hsl(306deg 32% 85%) 78%,
            hsl(306deg 27% 76%) 85%,
            hsl(306deg 25% 67%) 93%,
            hsl(306deg 23% 58%) 100%
          )   }
  }

  p {
    text-align: justify;
    font-size: 18px;
  }

  h3 {
    font-weight: normal;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
    }

    .wrapper {
      width: 50%;

      @media only screen and (max-width: 600px) {
        width: 80%;
      }
    }

    .wrapper.left {
      margin-top: 50px;
      span {
        font-weight: bold;
      }
    }
    .wrapper.right {
      display: flex;
      width: 30%;
      flex-wrap: wrap;
      margin-left: 50px;
      margin-top: 50px;

      @media only screen and (max-width: 600px) {
        width: 80%;
        margin-left: 0;
        justify-content: flex-start;
      }

      .module {
        margin-bottom: 10px;
      }
      .module-body {
        margin: 0;
      }

      .title {
        font-size: 48px;
        line-height: 52px;
        text-align: left;

        a {
          text-decoration: none;
          font-style: italic;
          cursor: pointer;
          color: #ffe971;
        }
      }
    }
  }


</style>
