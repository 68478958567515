<template>
  <div class="about page">
    <Navigation />
    <div class="container">
      <div class="inner">
        <div class="row first">
          <div class="wrapper left">
            <img src="../assets/logo.png">
          </div>
          <div class="wrapper right">
            <h1 class="title">Biedrība<br/>Izaugsme Plus </h1>
            <h4 class="info">
              Vienotais reģistrācijas nr.: 40008226832<br/>
              Adrese: Lokomotīves iela 94-22<br/>
              Tālrunis: 26463754;29418680<br/>
              E-pasts: anita@izaugsmeplus.lv<br/>
              Banka: Luminor<br/>
              Konta nr: LV21RIKO0002930358378<br/>
              SWIFT: RIKOLV2X<br/>
            </h4>
          </div>
        </div>
        <div class="row second">
          <h3 class="title">Par mums </h3>
          <div class="wrapper left">
            <p class="body">Izaugsme Plus" ir biedrība, kura specializējas pedagogu profesionālās kompetences attīstīšanā un pilnveidē, izmantojot inovatīvas un radošas mācību metodes. Biedrība īsteno skolotāju profesionālās darbības  pilnveides programmas, piedāvā konsultācijas interesentiem Biedrības  darbība veicina inovatīvu un radošu pieeju pedagoģiskajā procesā un palīdz pedagogiem attīstīt un pilnveidot savas profesionālās kompetences, lai nodrošinātu radošu, izzinošu un personificētu mācību vidi, kas atbilst mūsdienu izglītības vajadzībām un skola 2030 būtiskākajām atziņām, pievēršot uzmanību skolēna spējai saskaņoti lietot zināšanas un prasmes daudzveidīgās situācijās, gan caurviju prasmju attīstībai un ieradumu nostiprināšanai, kas balstīti vērtībās.</p>
          </div>
          <div class="wrapper right">
            <h3 class="subtitle">Vīzija</h3>
            <p class="body">
            Skolotājs ir kompass, kas mainīgajā pasaulē skolēnos aktivizē zinātkāres, zināšanu un gudrības magnētus.
            </p>
            <h3 class="subtitle">Misija</h3>
            <p class="body">Sadarbībā balstīta izglītības biedrība, kura piedāvā kursus pedagogiem  savas profesionālās kompetences potenciāla īstenošanai radošā, inovatīvā pedagoģiskajā procesā</p>
          </div>
        </div>
        <div class="row third people">
          <h3 class="title">Mācībspēki</h3>
          <div class="wrapper left">
            <h3 class="title__person">Anita Petere </h3>
            <p class="body">Daudzu gadu pieredze darbá ar studentiem, strādājot par profesori Rīgas Pedagoģijas un izglīības vadības akadēmijā kopš RPIVA izveides. Pēc RPIVA likvidēšanas profesores darba pienākumi tika veikti LU
  Izstrādātas un licencētas profesionālā bakalaura studiju programmas gan pirmsskolas, gan sākumizglītības skolotājiem. Izstrādāti un vadīti dažādi lekciju kursi integrētās, starpdisciplinārās pieejas īstenošanai pedagoģiskajā procesā, izmantojot daudzveidīgas mācību metodes un organizācijas formas.
  Papildus darbam LU, izstrādātas un vadītas vairākas programmas skolotāju tālākizglītībai. Lasītas lekcijas tālākizglitības kursos.
  Akadēmisko zināšanu pieredze pilnveidota gan veicot pētniecisko darbu Fulbright stipendijas ietvaros Nebraskas Universitātē ASV, gan lasot lekcijas Portugālē, Vācijā. Gūtā pētnieciskā darba pieredze atspoguļota vairākās starptautiskās publikācijās un konferencēs.
  Gūta darba pieredze iesaistoties projektā - Kompetenču pieeja mācību saturā Nr.8.3.1. Integrēta mācību satura izstrāde.
  Izstrādāta grāmata pedagogiem “Integrētās mācības pamatizglītībā”</p>
          </div>
          <div class="wrapper right">
            <img class="portrait" src="../assets/anita.png">
          </div>
        </div>
        <div class="row fourth people">
          <div class="wrapper left">
            <img class="portrait" src="../assets/daiga.png">
          </div>
          <div class="wrapper right">
            <h3 class="title__person">Daiga Kalēja-Gasparoviča</h3>
            <p class="body">Daudzu gadu pieredze darbā ar studentiem, topošajiem skolotājiem, LU studiju kursā Kultūras izpratnes un pašizpausmes mākslā mācību jomas saturs un mācīšanās pieejas: Vizuālās mākslas metodika, vadot tālākizglītības kursus skolotājiem dažādos Latvijas reģionos. 
   Profesionālais izaicinājums -  palīdzēt topošajiem/esošajiem skolotājiem mācīties patstāvīgi plānot un mērķtiecīgi īstenot skolēna radošuma attīstību sekmējošu mācību procesu dažādās izglītības jomās, lai skolēns bez pratības Kultūras izpratnē un pašizpausmē mākslā mācību jomā,.
   gūst pašvadītas mācīšanās, kritiskas domāšanas un problēmu risināšanas, jaunrades, sadarbības un pilsoniskās līdzdalības pieredzi, pilnveido caurviju prasmes. Māksla netiek uztverta kā mērķis, bet kā līdzeklis daudzveidīgu personīgu, sociālu, kulturālu mērķu sasniegšanai.
  Izstrādātas mācību grāmatas skolēniem un skolotājiem, mācību līdzekļi studentiem.
  Veikti eksperta pienākumi projektā Kompetenču pieeja mācību saturā un Kompetenču pieejā balstīta vispārējās izglītības satura aprobācija un ieviešana mācību saturā.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'AboutView',
  components: {
    Navigation
  }
}
</script>

<style scoped lang="scss">
  .inner {
    margin-top: 70px;
  }
  .about {
    background: linear-gradient(0deg, #B59AEE 0%, rgba(45, 0, 141, 0) 100%);
    height: auto;
  }

  p {
    text-align: left;
    font-size: 18px;
  }

  h3 {
    font-weight: normal;
  }

  .row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .row .wrapper {
    width: 35%;

    @media only screen and (max-width: 600px) {
      width: 80%;
    }
  }

  .row.first {
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 0;
    }

    .wrapper {
      width: auto;

      @media only screen and (max-width: 600px) {
        width: 80%;
      }
    }

    .wrapper.left {
      margin-right: 40px;

      @media only screen and (max-width: 600px) {
        margin: 0;
      }

      img {
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }

    .wrapper.right {
      @media only screen and (max-width: 600px) {
        margin-top: 40px;
      }
    }

    .title {
      font-weight: normal;
      text-align: left;
      margin: 0;
      font-size: 36px;
      line-height: 36px;
    }
    .info {
      font-weight: normal;
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .row.second {
    margin-bottom: 100px;
    align-items: center;

    @media only screen and (max-width: 600px) {
      margin-bottom: 40px;
    }


    .title {
      font-style: italic;
      text-align: left;
      font-size: 36px;
      width: 87%;

      @media only screen and (max-width: 600px) {
        width: 80%;
        margin-bottom: 10px;
      }

    }
    .subtitle {
      text-align: left;
      font-size: 36px;
      font-style: normal;
      margin: 0;
    }
    .wrapper.left {
      .body {
        text-align: justify;
      }
    }
    .wrapper.right {
      .body {
      //  width: 80%;
        text-align: justify;
      }
    }
  }

  .row.third {
    margin-bottom: 100px;
  }

  .row.people {
    .title {
      text-align: left;
      font-size: 36px;
      width: 87%;
      font-style: italic;
      margin-top: 0;
      margin-bottom: 52px;

      @media only screen and (max-width: 600px) {
        width: 80%;
        margin-bottom: 30px;
      }
    }

    .title__person {
      text-align: left;
      font-size: 24px;
      border-bottom: 1px solid;
      margin-top: 0;
    }

    .body {
      text-align: justify;
    }
    .portrait {
      width: 490px;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }

    @media only screen and (max-width: 600px) {
      .wrapper.left {
        order: 2;
      }
      .wrapper.right {
        order: 1;
      }
    }

  }
  .row.third {
    @media only screen and (max-width: 600px) {
      margin-bottom: 50px;
    }
  }
  .row.fourth {
    @media only screen and (max-width: 600px) {
      padding-bottom: 50px;

      .wrapper.left {
        order: 1;
      }
      .wrapper.right {
        order: 2;
      }
    }

  }

</style>
